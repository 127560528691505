<template>
    <div class="wrapper">
        <CRow>
            <CCol lg="12">
                <CButton
                    @click="$router.go(-1)"
                    size="sm"
                    color="warning" 
                > 
                    <font-awesome-icon icon="arrow-left"/> Go back
                </CButton>
            </CCol>
        </CRow>
        <br>
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="pencil-alt"/> Manage Field
                       

                    <template v-if="field.status == 'Active'">
                        <CButton 
                            size="sm"
                            color="danger" 
                            class="float-right" 
                            @click="toggleEdit(), returnInformation()" 
                            v-show="edit"
                        >
                            <font-awesome-icon icon="window-close"/> Cancel
                        </CButton>

                        <CButton 
                            v-if="$store.getters['can']('update-field')"
                            size="sm"
                            :disabled="!isValid"
                            color="success" 
                            class="float-right" 
                            v-show="edit" 
                            @click="updateField()"
                        >
                            <font-awesome-icon icon="save"/> Update
                        </CButton>

                        <CButton 
                            v-if="$store.getters['can']('edit-field')"
                            size="sm"
                            color="info" 
                            class="float-right" 
                            @click="toggleEdit" v-show="!edit"
                        >
                            <font-awesome-icon icon="edit"/> Edit
                        </CButton>
                    </template>
                    <template v-if="field.status == 'Archived'">
                        <CButton 
                            v-if="$store.getters['can']('restore-field')"
                            size="sm"
                            color="success" 
                            class="float-right" 
                            @click="restore()"
                        >
                        <font-awesome-icon icon="trash-restore"/> Restore
                        </CButton>
                    </template>
                    <a-popover title="NOTE:" placement="left">
                            <template slot="content">
                                <FieldHelp/>
                            </template>
                            <font-awesome-icon 
                                style="color: #4caf50; float: right; margin-right: 20px; margin-top: 5px;" 
                                icon="question-circle" 
                                size="lg"
                            />
                        </a-popover>

                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="3">
                                Status
                            </CCol>
                            <CCol lg="9">
                                <CBadge :color="getColorStatus(field.status)">{{field.status}}</CBadge>
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                Field Type
                            </CCol>
                            <CCol lg="9">
                                <!-- :class="!group_required ? 'has-error' : 'has-success'" -->
                                <v-select 
                                    disabled
                                    placeholder="Select one."
                                    label="name"
                                    :options="types"
                                    :reduce="types => types.name"
                                    v-model="drpdwn_type"
                                />
                            </CCol>
                        </CRow>
                        <br>
                        <CRow>
                            <CCol lg="3">
                                Display Name
                            </CCol>
                            <CCol lg="9">
                                <CInput 
                                
                                    :disabled="disable == 1 ? true : false"
                                    :lazy="false"
                                    :value.sync="$v.field.display_name.$model"
                                    :isValid="checkIfValid($v.field, 'display_name')"
                                    type="text" 
                                    placeholder="Enter display name." 
                                    autocomplete="off"  
                                    v-model="field.display_name"
                                />
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="3">
                                Parameter Name
                            </CCol>
                            <CCol lg="9">
                                <CInput 
                                    :disabled="disable == 1 ? true : false"
                                    :lazy="false"
                                    :value.sync="$v.field.parameter_name.$model"
                                    :isValid="checkIfValid($v.field, 'parameter_name')"
                                    type="text" 
                                    placeholder="Enter parameter name." 
                                    autocomplete="off"  
                                    v-model="field.parameter_name"
                                />
                            </CCol>
                        </CRow>
                        <template v-if="drpdwn_type != 'label'">
                            <CRow>
                                <CCol lg="3">
                                    Default Value
                                </CCol>
                                <CCol lg="9">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        type="text" 
                                        placeholder="Enter default value." 
                                        autocomplete="off"  
                                        v-model="field.default_value"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="3">
                                    Source
                                </CCol>
                                <CCol lg="9">
                                        <!-- :class="!edit || drpdwn_type != 'dropdown'? !drpdwn_source_type ? 'has-error' : 'has-success' : ''" -->

                                    <v-select 
                                        :disabled="disable == 1 ? true : (drpdwn_type == 'dropdown' || drpdwn_type == 'input_search') ? false : true"
                                        placeholder="Select one."
                                        label="name"
                                        :options="sources"
                                        v-model="drpdwn_source_type"
                                        :reduce="sources => sources.name"
                                    />
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol lg="3">
                                </CCol>
                                <CCol lg="9">

                                    <template v-if="field.source_type == 'api'">
                                        <v-select 
                                            :disabled="field.source_type=='none' || disable == 1"
                                            placeholder="Select one."
                                            label="Method"
                                            v-model="field.api_method"
                                            :options="['GET','POST']"
                                            :class="!field.api_method ? 'has-error' : 'has-success'"
                                        />
                                        <br>
                                        <CInput 
                                            :disabled="field.source_type=='none' || disable == 1"
                                            :lazy="false"
                                            :value.sync="$v.field.source.$model"
                                            :isValid="checkIfValid($v.field, 'source')"
                                            type="text" 
                                            placeholder="Enter source" 
                                            autocomplete="off"  
                                            v-model="field.source"
                                        />
                                    </template>
                                    <template v-if="field.source_type == 'excel' || field.source_type == 'json'">
                                        <input 
                                            :class="field.source_file ? 'external_file_valid' : 'external_file_invalid'"
                                            ref="external_file" 
                                            type="file" 
                                            v-on:change="onFileChange"
                                            :accept="field.source_type == 'excel' ? '.xlsx' : '.json'"
                                        />
                                        &nbsp;
                                        <a-popover title="NOTE:" placement="topRight">
                                            <template slot="content">
                                            <h6>Excel file guidelines:</h6>
                                            <span>* File type must be excel.</span> <br>
                                            <span>* File extension must be .xlsx.</span><br>
                                            <span>* File size must be less than 5mb.</span><br><br>
                                            <span style="color: red;">> Only read Sheet 1 and Column A, values <br> 
                                                &nbsp;&nbsp; that are not in the said sheet and column <br>
                                                &nbsp;&nbsp; will be discarded.  </span><br>
                                            
                                            </template>
                                                <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                                        </a-popover>
                                        <br>
                                        <br>
                                    </template>
                                    <template v-if="field.source_type == 'static' && field.source_static.length <= 29">
                                        <v-select 
                                            :disabled="disable == 1 ? true : false"
                                            taggable 
                                            multiple push-tags 
                                            v-model="field.source_static"
                                            :class="edit ? field.source_static.length <= 0  ? 'has-error' : 'has-success' : ''"
                                        />
                                        <br>
                                    </template>
                                    
                                    <template v-if="field.source_type == 'static' && field.source_static.length >= 30">
                                        <CButton
                                            size="sm"
                                            color="info" 
                                            :disabled="disable == 1 ? true : false"
                                            @click="openList()"
                                        >
                                            <font-awesome-icon icon="folder-open" /> Open List
                                        </CButton>
                                        <br>
                                        <br>
                                    </template>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol lg="3">
                                    Validations
                                </CCol>
                                <CCol lg="9">
                                    <CInput 
                                        :disabled="disable == 1 ? true : false"
                                        type="text" 
                                        placeholder="(e.g 'required', 'max:20')" 
                                        autocomplete="off"  
                                        v-model="field.validation"
                                    />
                                </CCol>
                            </CRow>

                        </template>
                        <template v-else>
                            <CRow>
                                <CCol lg="3">
                                    Label Content
                                </CCol>
                                <CCol lg="9">
                                    Label Format: 
                                    <p-radio class="p-icon p-curve p-pulse p-bigger" name="radio66" color="success" value="left" v-model="field.label_format" :disabled="disable == 1">
                                        <font-awesome-icon class="icon" icon="check"/>
                                        Left
                                    </p-radio>
                                    <p-radio class="p-icon p-curve p-pulse p-bigger" name="radio66" color="success"  value="center" v-model="field.label_format" :disabled="disable == 1">
                                        <font-awesome-icon class="icon" icon="check"/>
                                        Center
                                    </p-radio>
                                    <p-radio class="p-icon p-curve p-pulse p-bigger" name="radio66" color="success"  value="right" v-model="field.label_format" :disabled="disable == 1">
                                        <font-awesome-icon class="icon" icon="check"/>
                                        Right
                                    </p-radio>
                                </CCol>
                            </CRow>
                            <br>
                            <CRow>
                                <CCol lg="12">
                                    <CTextarea class="grp-text-area-in-field" v-model="field.label_value" :disabled="disable == 1">

                                    </CTextarea>
                                </CCol>
                            </CRow>
                            <hr>
                            <br>
                            <CRow>
                                <CCol lg="12">
                                    <h4>Label Preview</h4>
                                    <!-- <div style="white-space: pre-line;">{{field.label_value}}</div> -->
                                    <div :style="`white-space: pre-line; text-align:${field.label_format}` ">{{field.label_value}}</div>
                                </CCol>
                            </CRow>
                        </template>
                    </CCardBody>
                </CCard>
                <CRow v-show="edit">
                    <CCol lg="12">
                        <CCard borderColor="danger" v-if="$store.getters['can']('archive-field')">
                            <CCardHeader>
                                <font-awesome-icon 
                                    icon="exclamation" 
                                    :style="{ color: 'red' }"
                                /> 
                                Danger Zone
                            </CCardHeader>
                            <CCardBody>
                                <CRow>
                                    <CCol lg="8">
                                        <h6>Archive this Field ?</h6>
                                    </CCol>
                                    <CCol lg="12">
                                        <label> Once archived, this field cannot used anymore. Please be certain.</label>
                                    </CCol>
                                </CRow>
                                <br>
                                <CRow>
                                    <CCol lg="5">
                                        <CButton shape="pill" color="warning" @click="archive()" > 
                                            <font-awesome-icon icon="minus-square" /> Archived 
                                        </CButton>
                                    </CCol>
                                </CRow>
                            </CCardBody>
                        </CCard>
                    </CCol>
                </CRow>
            </CCol>
        </CRow>
        <CModal
            :show.sync="large_list_modal"
            :closeOnBackdrop="false"
            color="primary"
            size="lg"
            >
            <template #header>
                <h6> <font-awesome-icon icon="list"/> List </h6>
                <CButton 
                    class="pull-right" 
                    color="light" 
                    shape="pill" 
                    size="sm"
                    @click="large_list_modal = false"
                >
                    <font-awesome-icon  size="sm" icon="times"/>
                </CButton>
            </template>
            <template #footer>
                <hr>
            </template>
            <template #body-wrapper>
                <CCardBody>
                    <CRow>
                        <CCol lg="11">
                            
                            <CInput 
                                type="text" 
                                placeholder="New Value..." 
                                autocomplete="off" 
                                v-model="new_value"
                            />
                        </CCol>
                        <CCol lg="1">
                            <CButton 
                            color="primary" 
                            @click="addNewValue()"
                            :disabled="!new_value"
                            >
                                <font-awesome-icon icon="plus"/>
                            </CButton>
                        </CCol>
                    </CRow>
                    <br>
                    <br>
                    <CRow>
                        <CCol lg="6">
                            <CInput 
                                type="text" 
                                placeholder="Search" 
                                autocomplete="off" 
                                v-model="large_list_search"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="12">
                            <CDataTable
                                :key="large_list_modal_key"
                                :items="large_list_items"
                                :fields="large_list_field"
                                :items-per-page="30"
                                border
                                size="sm"
                                sorter
                                pagination
                            >
                                <template #created_at="{item, index}">
                                    <td>
                                        <template v-if="item.id">
                                            {{ changeDateFormatV2(item.created_at) }}
                                        </template>
                                        <template v-else>
                                            <i>Recently added</i>
                                        </template>
                                    </td>
                                </template>
                                <template #action="{item, index}">
                                    <td>
                                        <center>
                                            <CButton 
                                                color="danger" 
                                                shape="pill" 
                                                size="sm"
                                                @click="removeRow(item, index)"
                                            >
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                        </center>
                                    </td>
                                </template>
                            </CDataTable>
                        </CCol>
                    </CRow>
                </CCardBody>
            </template>
        </CModal>
    </div>
</template>
<script>
import { required, requiredIf, url} from "vuelidate/lib/validators"
import FieldHelp from '../components/FieldHelp.vue';
export default {
    name: 'FieldEdit',
    components: {
        FieldHelp,
    },
    data() {
        return {

            new_value:'',
            
            large_list_search: '',
            large_list_modal: false,
            large_list_modal_key: 0,
            large_list: [],
            large_list_field: [
                { key: 'name' },
                { key: 'created_at', label: 'Date Added' },
                { key: 'action' },
            ],


            disable: 1,
            edit: false,

            drpdwn_type:'',
            drpdwn_source_type:'',
            drpdwn_default_value: '',
            source_type_handler: null,

            types: [],
            

            field: {
                field_type_id: '',
                display_name: null,
                parameter_name: null,
                field_type_name: null,
                source_type: '',
                default_value: '',
                
                source: null,
                source_static: [],
                source_file: null,
                validation: null,
                status: null,
                api_method: null,
                label_value: null,
                label_format: null,
            },
            field_backup: {
                field_type_id: '',
                display_name: null,
                parameter_name: null,
                field_type_name: null,
                source_type: '',
                default_value: '',
                
                source: null,
                source_static: [],
                validation: null,
                status: null,
                api_method: null,
                label_value: null,
                label_format: null,
            }
        }
    },
    validations: {
        field: {
            field_type_id: { required, },
            display_name: { required, },
            parameter_name: { required },
            source_type: { required, },
            source: {
                required: requiredIf(function (){
                   return this.field.source_type == 'api'
                }),
                url,
            },
            api_method: {
                required: requiredIf(function (){
                   return this.field.source_type == 'api'
                }),
            },
            source_static: {
                required: requiredIf(function () {
                    return this.field.source_type == 'static'
                })
            },
            source_file: {
                required: requiredIf(function () {
                    return this.field.source_type == 'excel'
                })
            }
        },
    },
    computed: {
        large_list_items: function () {
            return this.large_list.filter((e) => e.name.toLowerCase().indexOf(this.large_list_search.toLowerCase()) > -1);
        },
        isValid () { return !this.$v.field.$invalid },
        isDirty () { return this.$v.field.$anyDirty },

        
        default_values : function () {
            let custom =  [
                {name: 'now()'}, 
            ]

            if(this.field.field_type_id == 4) {
                custom.push({ name: 'null' })
            }
            return custom;
        },

        sources : function () {
            let custom =  [
                {name: 'api'}, 
                {name: 'static'}, 
                {name: 'excel'},
                // {name: 'json'}
            ]

            if(this.field.field_type_name != 'dropdown' && this.field.field_type_name != 'input_search') {
                custom.push({ name: 'none' })
            }
            return custom;
        }
    },
    created() {
        this.$emit('activeTab', 0);
        this.$Progress.start()
        this.getField();
        this.getFieldTypes();
    },
    methods: {
        removeRow: function (item, index) { 
            this.field.source_static.splice(index, 1)
        },
        addNewValue: function () { 
            this.field.source_static.unshift({id: null, name: this.new_value});
            this.new_value = ''
        },
        openList: function () {
            if(this.field.source_static.length >= 30) {
                this.large_list = this.field.source_static
                this.large_list_modal = true;
                this.large_list_modal_key + 1;
            }
        },
        clearExternalFile:function()
        {
            const input = this.$refs.external_file;
            input.type = 'text';
            input.type = 'file';
        },
        onFileChange:function (e)
        {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.clearExternalFile();
                this.field.source_file = null;
                return;
            }

            if(files[0].type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') 
            {
                this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    showConfirmButton: false,
                    timer: 5000,
                    icon: 'error',
                    title: `Invalid file. Only accept .xlsx type.`,
                    timerProgressBar: true,
                })

                this.field.source_file = null;
                this.clearExternalFile();
                return;
            }

            if (files[0].size / Math.pow(1024,1) > 5000) {
                this.$swal({
                    customClass: 'ta-left',
                    toast: true,
                    position: 'top-right',
                    timer: 5000,
                    icon: 'error',
                    title: `File is too large. Maximum size is 5MB.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })

                this.field.source_file = null;
                this.clearExternalFile();
                return;
            }

            this.field.source_file = files[0]
        },
        restore: function () {
            this.$Progress.start()
            axios.post('/form-managements/field/restore/' + this.$route.params.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        timer: 3000,
                        icon: 'success',
                        title: `${this.field.display_name} Field successfully restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.field.status =  'Active';
                    this.$Progress.finish()
                }
            })
        },
        archive: function () {
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                html: `You are trying to archive <b>${this.field.display_name}</b> field.`,
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    this.$Progress.start()
                    return axios.post('/form-managements/field/archive/' + this.$route.params.id, {validateStatus: () => true})
                    .then(response => {
                        if(response.status == 200) {
                            this.$swal({
                                toast: true,
                                position: 'top-right',
                                timer: 3000,
                                icon: 'success',
                                title: `${this.field.display_name} Field successfully archived.`,
                                showConfirmButton: false,
                                timerProgressBar: true,
                            })
                            this.field.status = 'Archived';
                            this.toggleEdit()
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
        toggleEdit: function() {
            this.edit = !this.edit
            this.disable = (this.disable + 1) % 2
            if(!this.disable) { 
                this.$v.$touch()
            }
            else {
                this.$v.$reset()
            }
        },
        returnInformation: function () {
            // ! DEEP CLONE //
            this.field = JSON.parse(JSON.stringify(this.field_backup));
            this.drpdwn_source_type = this.field.source_type
        },
        getFieldTypes: function () {
            axios.get('/form-managements/field-type/active-list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.types = response.data.data
                }
            })
        },
        getField: function () {
            axios.get('/form-managements/field/'+this.$route.params.id, {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    this.assignData(response.data.data)
                }
            })
        },
        assignData: function (data) {
            this.field.source_static = []
            this.drpdwn_type = data.field_type.name
            this.drpdwn_source_type = data.source_type;
            this.source_type_handler = {name: data.source_type}
            this.field.field_type_id = data.field_type_id;
            this.field.display_name = data.display_name;
            this.field.label_value = data.label_value
            this.field.label_format = data.label_format
            this.field.parameter_name = data.parameter_name;
            this.field.source_type = data.source_type;
            this.field.default_value = data.default_value
            this.field.source = data.source;
            this.field.api_method = data.api_method;
            this.field.validation = data.validation;
            this.field.field_type_name =  data.field_type.name
            this.field.status = data.deleted_at == null ? 'Active' : 'Archived';

            if(data.source_type != 'api') {
                if(data.field_drop_down_values_data.length <= 29) {
                    for (let index = 0; index < data.field_drop_down_values_data.length; index++) {
                        const element = data.field_drop_down_values_data[index];
                        this.field.source_static.push(element.value);
                    }

                } else {
                    for (let index = 0; index < data.field_drop_down_values_data.length; index++) {
                        const element = data.field_drop_down_values_data[index];
                        this.field.source_static.push({id: element.id, name: element.value, created_at: element.created_at});
                    }
                }
            }

            this.field_backup = JSON.parse(JSON.stringify(this.field));
            this.$Progress.finish();
        },
        updateField: function (){

            if(JSON.stringify(this.field) == JSON.stringify(this.field_backup)) {
               this.$swal({
                    icon: "info",
                    title: "Opps!",
                    text: "No changes has been made.",
                })
                return;
            }
            this.$swal({
                icon: "warning",
                title: "Are you sure?",
                text: "You are trying to update the Field.",
                showCancelButton: true,
                confirmButtonColor: "#DD6B55",
                confirmButtonText: "Yes",
                showLoaderOnConfirm: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                preConfirm: () => { 
                    let data = new FormData();
                    data.append('api_method', this.field.api_method);
                    data.append('display_name', this.field.display_name);
                    data.append('field_type_id', this.field.field_type_id);
                    data.append('field_type_name', this.field.field_type_name);
                    data.append('label_format', this.field.label_format);
                    data.append('label_value', this.field.label_value);
                    data.append('parameter_name', this.field.parameter_name);
                    data.append('source', this.field.source);
                    data.append('source_file', this.field.source_file);
                    let str = '';
                    for (let index = 0; index < this.field.source_static.length; index++) {
                        const element = this.field.source_static[index];
                        if( (typeof this.field.source_static[0]) === 'object') {  
                            str += `${element.name}@dlmtr@`;
                        } else {
                            str += `${element}@dlmtr@`;
                        }
                    }
                    data.append('source_static', str)
                    data.append('source_type', this.field.source_type);
                    data.append('validation', this.field.validation);
                    data.append('default_value', this.field.default_value);
                 
                    if(!this.field.validation) {
                        delete this.field.validation;
                        data.delete("validation");
                    }

                    if(!this.field.default_value) {
                        delete this.field.default_value;
                        data.delete("default_value");
                    }

                    if(this.field.source_type == 'api') {
                        delete this.field.source_static;
                        data.delete("source_static");
                    }

                    if(this.field.source_type == 'excel') {
                        delete this.field.source_static;
                        data.delete("source_static");
                    }

                    this.$Progress.start()
                    return axios.post('/form-managements/field/update/'+this.$route.params.id, data, {validateStatus: () => true})
                    .then(response => {
                        if ( response.status == 200 ) {
                            this.form_reset= true;
                            this.$swal({
                                icon: "success",
                                title: "Success!",
                                html: `<b>${this.field.display_name}</b> has been updated.`,
                            })
                    
                            this.assignData(response.data.data)
                            
                            this.toggleEdit();
                            this.$Progress.finish()
                        }
                    })
                }
            }).then((result) => {
                if(!result.value){
                    this.$swal('Cancelled!','','error');
                }
            })
        },
    },
    watch: {
        drpdwn_source_type: function (value) {
            if(value == 'excel') {
                return this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "Please note that once the new excel file has been upload and submitted, it will wipe out all the previous list of this field. Do you still want to continue?",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes, I understand.",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    }).then((result) => {
                    if(!result.value){
                        this.$swal('Cancelled!','','error');
                        return;
                    } else {
                        this.field.source_type = null;
                        this.field.source_file = null;
                        if(value) this.field.source_type = value
                    }
                });
            }
            this.field.source_type = null;
            this.field.source_file = null;
            if(value) this.field.source_type = value
        },
        large_list_modal: function (val) {
            if(!val) this.large_list = []
        }
    }
}
</script>
<style>
    .external_file_valid {
        border: solid green 1px;
        border-radius: 5px;
    }
    .external_file_invalid {
        border: solid red 1px;
        border-radius: 5px;
    }
</style>                  